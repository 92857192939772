const navRoutes = {
    'sheffield-property-maintenance': 'sheffield-property-maintenance',
    'rotherham-property-maintenance': 'rotherham-property-maintenance',
    'chesterfield-property-maintenance': 'chesterfield-property-maintenance',
    'barnsley-property-maintenance': 'barnsley-property-maintenance',
    'worksop-property-maintenance': 'worksop-property-maintenance',
    'doncaster-property-maintenance': 'doncaster-property-maintenance',
    'hillsborough-property-maintenance': 'hillsborough-property-maintenance',
    'woodseats-property-maintenance': 'woodseats-property-maintenance',
    'ecclesall-property-maintenance': 'ecclesall-property-maintenance',
    'meadowhall-property-maintenance': 'meadowhall-property-maintenance',
    'dronfield-property-maintenance': 'dronfield-property-maintenance',
    'stocksbridge-property-maintenance': 'stocksbridge-property-maintenance',
    'totley-property-maintenance': 'totley-property-maintenance',
    'walkley-property-maintenance': 'walkley-property-maintenance',
    'crookes-property-maintenance': 'crookes-property-maintenance',
    'firth-park-property-maintenance': 'firth-park-property-maintenance',
    'stannington-property-maintenance': 'stannington-property-maintenance',
    'mosborough-property-maintenance': 'mosborough-property-maintenance',
    'handsworth-property-maintenance': 'handsworth-property-maintenance',
    'hackenthorpe-property-maintenance': 'hackenthorpe-property-maintenance',
    'gleadless-property-maintenance': 'gleadless-property-maintenance',
    'beighton-property-maintenance': 'beighton-property-maintenance',
    'hemsworth-property-maintenance': 'hemsworth-property-maintenance',
    'bramley-property-maintenance': 'bramley-property-maintenance',
    'wath-upon-dearne-property-maintenance': 'wath-upon-dearne-property-maintenance',
    'conisbrough-property-maintenance': 'conisbrough-property-maintenance',
    'brinsworth-property-maintenance': 'brinsworth-property-maintenance',
    'swallownest-property-maintenance': 'swallownest-property-maintenance',
    'killamarsh-property-maintenance': 'killamarsh-property-maintenance',
    'clowne-property-maintenance': 'clowne-property-maintenance',
    'matlock-property-maintenance': 'matlock-property-maintenance',
    'bakewell-property-maintenance': 'bakewell-property-maintenance',
    'hope-property-maintenance': 'hope-property-maintenance',
    'hathersage-property-maintenance': 'hathersage-property-maintenance',
    'baslow-property-maintenance': 'baslow-property-maintenance',
    'sheffield-handyman': 'sheffield-handyman',
    'rotherham-handyman': 'rotherham-handyman',
    'chesterfield-handyman': 'chesterfield-handyman'
};

module.exports = navRoutes;
